<template>
  <div class="container">
    <Disclaimer v-show="disclaimer && !disclaimerIsShown"></Disclaimer>

    <div class="guidelines-wrapper" :class="{'no-sidebar': guideId === shahutaharkId}">
      <div class="guidelines-sidebar-wrapper" id="sidebarWrapper"
           v-show="guideId !== shahutaharkId">
        <sectionsList></sectionsList>
      </div>
      <div class="guidelines-content-wrapper"
           v-scroll="onScroll">
        <div class="disclaimer" v-if="disclaimer">
          {{disclaimer}}
        </div>
        <template v-if="sections && sections.length">
          <section v-for="section in sections" :key="section.id"
                   class="guide-section f_sectionAnchor" :id="section.id">
            <h3 class="text-center section-title">{{section.sectionTitle}}</h3>
            <h4 class="text-center title">{{section.title}}</h4>

            <div class="content" v-html="section.content"></div>
          </section>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import throttle from 'lodash.throttle';
import Cookies from 'js-cookie';

const sectionsList = () => import('./GuidSectionsList.vue');
const Disclaimer = () => import('../components/DisclaimerModal.vue');

export default {
  name: 'BaseView',
  components: {
    sectionsList,
    Disclaimer,
  },
  data() {
    return {
      shahutaharkId: 17,
    };
  },
  computed: {
    guideId() {
      return +this.$route.params.guideId;
    },
    disclaimer() {
      return this.$store.getters.getActiveGuide.description;
    },
    sections() {
      return this.$store.getters.getGuideSectionsList;
    },
    activeSectionId() {
      return this.$store.getters.getActiveSectionId;
    },
    disclaimerIsShown() {
      return this.$store.getters.getDisclaimerIsShown;
    },
  },
  methods: {
    onScroll: throttle(function () {
      this.setActiveHash();
    }, 300),
    setActiveHash() {
      const sidebarLinks = [].slice.call(document.querySelectorAll('.f_sidebarLink'));
      const anchors = [].slice.call(document.querySelectorAll('.f_sectionAnchor'))
        .filter(anchor => sidebarLinks.some(sidebarLink => sidebarLink.hash === anchor.hash));

      const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

      for (let i = 0; i < anchors.length; i++) {
        const anchor = anchors[i];
        const nextAnchor = anchors[i + 1];
        const isActive = i === 0 && scrollTop === 0 || (scrollTop >= anchor.offsetTop - 10
              && (!nextAnchor || scrollTop < nextAnchor.offsetTop - 10));
        const id = parseInt(anchor.id, 10);
        if (isActive && id !== this.activeSectionId) {
          this.$store.commit('setActiveSectionId', id);
          this.setRouteHash(id);
        }
      }
    },
    setRouteHash(id) {
      this.$router.replace({ name: this.$route.name, params: { guideId: this.$route.params.guideId }, hash: `#${id}` });
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/styles/views/guidePages.scss";
</style>
