import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueScrollTo from 'vue-scrollto';
import App from './App.vue';
import router from './router/router';
import store from './services/store';

//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/styles/style.scss';

Vue.directive('scroll', {
  inserted(el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener('scroll', f);
  },
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: -150,
  x: false,
  y: true,
});


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
