<template>
  <div class="pagination-container">
    <div class="pagination" v-if="totalPages && totalPages > 1">
      <div class="page-item">
        <div class="page-link chevron"
             :class="{'disabled': currentPage === 1}"
             @click="changePage('-')">&laquo;</div>
      </div>
      <div class="page-item"
           v-for="num in totalPages" :key="num">
        <div class="page-link" :class="{'is_active': currentPage === num}"
             @click="changePage(num)">{{num}}</div>
      </div>
      <div class="page-item">
        <div class="page-link chevron"
             :class="{'disabled': currentPage === totalPages}"
             @click="changePage('+')">&raquo;</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: ['totalPages'],
  computed: {
    currentPage() {
      return this.$store.getters.getCurrentPage;
    },
  },
  methods: {
    changePage(num) {
      let page;
      if (num === '-') {
        page = this.currentPage - 1;
      } else if (num === '+') {
        page = this.currentPage + 1;
      } else {
        page = num;
      }

      this.$store.commit('setCurrentPage', page);
    },
  },
};
</script>

<style scoped lang="scss">
  .pagination-container {
    text-align: right;

    .pagination {
      display: inline-flex;
      .page-item {
        margin: 0 5px;
      }
      .page-link {
        background-color: #eeeeee;
        padding: 4px 10px;
        border-radius: 3px;
        height: 30px;
        cursor: pointer;
        transition: background-color .2s ease 0s, color .2s ease 0s;
        &:hover {
          background-color: darken(#eeeeee, 10%);
        }
        &.is_active {
          background-color: $blueColor;
          color: #ffffff;
        }
      }
      .chevron {
        padding-top: 2px;
      }
    }
  }
</style>
