import Vue from 'vue';
import Router from 'vue-router';
import Home from '../components/layout/Home.vue';
import TaxInfo from '../views/TaxInfo.vue';
import TaxInfoArticle from '../views/TaxInfoArticle.vue';
import GuideSearchResults from '../views/GuideSearchResults.vue';
import ArticleSearchResults from '../views/ArticleSearchResults.vue';
import BaseTemplate from '../views/GuidePage.vue';
import WelcomePage from '../views/WelcomePage.vue';
import Feedback from '../views/Feedback.vue';
// example for importing component with promise
// const main = () => import(path);
Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      children: [
        { path: '/', component: WelcomePage, name: 'WelcomePage' },
        { path: '/tax-info', component: TaxInfo, name: 'TaxInfo' },
        { path: '/tax-info/article/:articleId', component: TaxInfoArticle, name: 'TaxInfoArticle' },
        { path: '/guide/:guideId', component: BaseTemplate, name: 'Guide' },
        { path: '/find-guide', component: GuideSearchResults, name: 'GuideSearchResults' },
        { path: '/find-article', component: ArticleSearchResults, name: 'ArticleSearchResults' },
        { path: '/feedback', component: Feedback, name: 'Feedback' },
      ],
    },
  ],
});
