<template>
  <div class="main-wrapper" :lang="lang"
       :style="activeRequestsCount > 0 ? 'overflow: hidden' : ''">
    <Header></Header>
    <div class="main-content">
      <router-view></router-view>
      <Preloader></Preloader>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Cookie from 'js-cookie';

const Header = () => import('./Header');
const Footer = () => import('./Footer');
const Preloader = () => import('../Preloader.vue');

export default {
  name: 'Home',
  components: {
    Header, Footer, Preloader,
  },
  created() {
    this.setCookie();
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    activeRequestsCount() {
      return this.$store.getters.getActiveRequestsCount;
    },
  },
  methods: {
    setCookie() {
      const cookieLang = Cookie.get('lang');
      if (cookieLang) {
        this.$store.commit('setLang', cookieLang);
      } else {
        this.$store.commit('setLang', 'hy');
      }
    },
  },
};
</script>
