import http from '../helpers/managers/requestManager';

export default {
  getGuideList({ commit }, lang) {
    commit('incrementActiveRequestsCount');
    return http.send({
      method: 'GET',
      url: 'guides/',
      headers: {
        'accept-language': lang,
      },
    }).then((res) => {
      commit('setGuideList', res);
      commit('decrementActiveRequestsCount');
      return res;
    }).catch((err) => {
      commit('decrementActiveRequestsCount');
      console.log(err);
    });
  },
  getGuideSectionsList({ commit }, params) {
    commit('incrementActiveRequestsCount');
    return http.send({
      method: 'GET',
      url: `guides/${params.id}/sections`,
      headers: {
        'accept-language': params.lang,
      },
    }).then((res) => {
      commit('setGuideSectionsList', res);
      commit('decrementActiveRequestsCount');
      return res;
    }).catch((err) => {
      commit('decrementActiveRequestsCount');
      console.log(err);
    });
  },
  getLegislationBookToc({ commit }, lang) {
    commit('incrementActiveRequestsCount');
    return http.send({
      method: 'GET',
      url: 'parts',
      headers: {
        'accept-language': lang,
      },
    }).then((res) => {
      commit('setLegislationBookToc', res);
      commit('decrementActiveRequestsCount');
    }).catch((err) => {
      commit('decrementActiveRequestsCount');
      console.log(err);
    });
  },
  getArticleData({ commit }, params) {
    commit('incrementActiveRequestsCount');
    return http.send({
      method: 'GET',
      url: `articles/${params.id}`,
      headers: {
        'accept-language': params.lang,
      },
    }).then((res) => {
      commit('setArticleData', res);
      commit('decrementActiveRequestsCount');
    }).catch((err) => {
      commit('decrementActiveRequestsCount');
      console.log(err);
    });
  },
  searchGuide({ commit }, params) {
    commit('incrementActiveRequestsCount');
    return http.send({
      method: 'GET',
      url: `guideSections/find?per_page=1&${params.page ? `page=${params.page}` : 1}&${params.query ? `query=${params.query}` : ''}`,
      headers: {
        'accept-language': params.lang,
      },
    }).then((res) => {
      commit('setSearchResults', res);
      commit('decrementActiveRequestsCount');
    }).catch((err) => {
      commit('decrementActiveRequestsCount');
      console.log(err);
    });
  },
  searchArticle({ commit }, params) {
    commit('incrementActiveRequestsCount');
    return http.send({
      method: 'GET',
      url: `articles/find?${params.page ? `page=${params.page}` : 1}&${params.query ? `query=${params.query}` : ''}`,
      headers: {
        'accept-language': params.lang,
      },
    }).then((res) => {
      commit('setSearchResults', res);
      commit('decrementActiveRequestsCount');
    }).catch((err) => {
      commit('decrementActiveRequestsCount');
      console.log(err);
    });
  },
  getPolls({ commit }, params) {
    commit('incrementActiveRequestsCount');
    return http.send({
      method: 'GET',
      url: 'polls',
      headers: {
        'accept-language': params.lang,
      },
    }).then((res) => {
      commit('setPolls', res);
      commit('decrementActiveRequestsCount');
    }).catch((err) => {
      commit('decrementActiveRequestsCount');
      console.log(err);
    });
  },
  submitPoll({ commit }, params) {
    commit('incrementActiveRequestsCount');
    return http.send({
      method: 'POST',
      url: 'polls/answer',
      headers: {
        'accept-language': params.lang,
      },
    }, params.data).then(() => {
      commit('decrementActiveRequestsCount');
    }).catch((err) => {
      commit('decrementActiveRequestsCount');
      console.log(err);
    });
  },
  getActiveGuide({ commit }, params) {
    commit('incrementActiveRequestsCount');
    return http.send({
      method: 'GET',
      url: `guides/${params.id}/guideName`,
      headers: {
        'accept-language': params.lang,
      },
    }).then((res) => {
      commit('setActiveGuide', res);
      commit('decrementActiveRequestsCount');
    }).catch((err) => {
      commit('decrementActiveRequestsCount');
      console.log(err);
    });
  },
};
