import am from '@/assets/translations/am.json';
import en from '@/assets/translations/en.json';

export default {
  lang: '',
  guideList: {},
  guideSectionsList: [],
  activeSectionId: null,
  articleData: {},
  legislationBookToc: [],
  translations: { hy: am, en },
  searchFor: 'guide',
  searchQuery: '',
  searchResults: {
    items: [],
    count: '',
    total: '',
  },
  currentPage: 1,
  activeRequestsCount: 0,
  polls: [],
  activeGuide: {
    description: '',
  },
  disclaimerIsShown: false,
};
