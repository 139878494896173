<template>
  <div class="container">
    <div class="search-results">
      <h3 class="title">{{translations['search_results']}}</h3>

      <template v-if="searchResults.items && searchResults.items.length">
        <router-link class="result"
                     :to="{name: 'TaxInfoArticle', params: {
                        articleId: result.id}, hash: `#${result.point ? result.point.id : ''}`
                      }"
                     v-for="result in searchResults.items" :key="result.id">
          <div class="result-title">
            {{result.part ? result.part.title : ''}}: {{result.chapter.title}}: {{result.section.title}}
          </div>
          <div class="content" v-html="getSearchResultContent(result.points[0].point)"></div>
        </router-link>
      </template>

      <pagination :totalPages="totalPages"></pagination>
    </div>
  </div>
</template>

<script>
import pagination from '../components/Pagination.vue';

export default {
  name: 'ArticleSearchResults',
  components: {
    pagination,
  },
  data() {
    return {
      searchGuide: 'guide',
      searchArticle: 'article',
      totalPages: 0,
    };
  },
  computed: {
    searchResults() {
      const searchResults = this.$store.getters.getSearchResults;
      this.totalPages = Math.ceil(searchResults.total / searchResults.count) || 0;
      return searchResults;
    },
    translations() {
      return this.$store.getters.getTranslations;
    },
    query() {
      return this.$store.getters.getSearchQuery;
    },
  },
  methods: {
    getSearchResultContent(content) {
      const queryString = this.query;
      const strippedContent = content.replace(/(<([^>]+)>)/ig, '');
      const index = strippedContent.toLowerCase().indexOf(queryString.toLowerCase());
      const CHAR_COUNT = 500;
      const trimmedContent = strippedContent.substring(index, index + CHAR_COUNT).replace('ՀՂՈւՄ', '');
      const regexp = new RegExp(queryString, 'gi');
      const highlightedContent = trimmedContent.replace(regexp, str => str.bold());

      return `...${highlightedContent}...`;
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/views/searchResults.scss';
</style>
