<template>
  <div class="container welcome-page">
    <div class="welcome-text">
      <template v-if="lang === hy">
        <h4 style="text-align: center; margin-bottom: 0px;">ԲԱՐԻ ԳԱԼՈՒՍՏ ՀՀ ՀԱՐԿԱՅԻՆ ՕՐԵՆՍԳՐՔԻ ՈՒՂԵՑՈՒՅՑՆԵՐԻ ԱՌՑԱՆՑ ՀԱՐԹԱԿ</h4>


  <b-container fluid class="p-4 bg-white">
  <b-row class="text-center" v-for="guideCollection in guideList" :key="guideCollection.id">

    <b-container fluid class="p-4 bg-white">
    <b-row class="text-center" style="display: inherit; margin-bottom: 20px;">
    {{guideCollection.title}}
    </b-row>
    <b-row class="text-center">
    <b-col cols="4"  v-for="guide in guideCollection.guides" :key="guide.id">
              <router-link :key="guide.id" v-if="guide.title && !(lang === 'en' && guide.id === 26)"
                                   @click.native="setSearchFor(searchGuide)"
                                   :class="{'sub-menu': guide.id === 18 || guide.id === 20}"
                                   :to="{name:'Guide', params: {guideId: guide.id}}">
                  <img :src="'/images/guide/'+guide.id+'.jpg'" alt="" />
                  <p style="center">  {{guide.title}} </p>
               </router-link>
    </b-col>
    </b-row>
    </b-container>
  </b-row>
</b-container>

        <p>
          Ուղեցույցները կրում են բացառապես ճանաչողական բնույթ: Ուղեցույցները հանդես չեն գալիս որպես
          իրավական ակտ և չեն կազմում ՀՀ հարկային հարաբերությունները կարգավորող օրենսդրության մաս:
          Ուղեցույցները չեն կարող հիմք հանդիսանալ ՀՀ հարկային հարաբերությունները կարգավորող
          օրենսդրության պաշտոնական պարզաբանման և (կամ) մեկնաբանման համար: Ուղեցույցներում
          ներկայացված պարզաբանումները և օրինակները, արված վերլուծությունները հանդես չեն գալիս որպես
          ՀՀ հարկային հարաբերությունները կարգավորող օրենսդրության կամ դրա առանձին նորմերի պաշտոնական
          պարզաբանումներ կամ մեկնաբանություններ: Ուղեցույցներին չի կարող հղում կատարվել որպես
          պաշտոնական իրավական աղբյուրի։
        </p>
      </template>
      <template v-if="lang === en">
        <h4 style="text-align: center; margin-bottom: 0px;">WELCOME TO THE ONLINE PLATFORM OF TAX CODE GUIDELINES</h4>
  <b-container fluid class="p-4 bg-white">
  <b-row class="text-center" v-for="guideCollection in guideList" :key="guideCollection.id">

    <b-container fluid class="p-4 bg-white">
    <b-row class="text-center" style="display: inherit; margin-bottom: 20px;">
    {{guideCollection.title}}
    </b-row>
    <b-row class="text-center">
    <b-col cols="4"  v-for="guide in guideCollection.guides" :key="guide.id">
              <router-link :key="guide.id" v-if="guide.title && !(lang === 'en' && guide.id === 26)"
                                   @click.native="setSearchFor(searchGuide)"
                                   :class="{'sub-menu': guide.id === 18 || guide.id === 20}"
                                   :to="{name:'Guide', params: {guideId: guide.id}}">
                  <img :src="'/images/guide/'+guide.id+'en.jpg'" alt="" />
                  <p style="center">  {{guide.title}} </p>
               </router-link>
    </b-col>
    </b-row>
    </b-container>
  </b-row>
</b-container>

        <p>
          The guidelines are only of informative nature. The guidelines are not deemed as a legal
          act and do not form part of the legislation regulating RA tax relations.The guidelines
          cannot be the basis for the official clarification and / or interpretation of the tax
          legislation of the Republic of Armenia.The clarifications, examples, and analyses
          presented in the Guidelines can not be considered as official clarifications or comments
          on the tax legislation of the Republic of Armenia or its separate norms.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomePage',
  data() {
    return {
      hy: 'hy',
      en: 'en',
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    guideList() {
      return this.$store.getters.getGuideList;
    },
    guideId() {
      return this.$route.params.guideId;
    },
    translations() {
      return this.$store.getters.getTranslations;
    },
  },
};
</script>

<style scoped lang="scss">
  @import "~@/assets/styles/views/welcomePage.scss"; 
  @import "~@/assets/styles/views/welcomePage2.scss";
//  @import "../../node_modules/bootstrap/dist/css/bootstrap.css";
</style>
