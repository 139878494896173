<template>
  <div class="container">
    <div class="tax-info-wrapper">
      <h5 class="title">{{translations['tax_code_title']}}</h5>
      <h6 class="subtitle">{{translations['toc']}}</h6>
      <template v-if="toc.length">
        <accordionItem :part="part" :partNumber="part.partNumber"
                       v-for="part in toc" :key="part.id">
          <accordionItem :part="section"
                         :partNumber="`${part.partNumber}.${section.sectionNumber}`"
                         v-for="section in part.sections" :key="`${part.id}.${section.id}`">
            <accordionItem :part="chapter"
                           :partNumber="`${part.partNumber}.${section.sectionNumber}.${chapter.chapterNumber}`"
                           v-for="chapter in section.chapters"
                           :key="`${part.id}.${section.id}.${chapter.id}`">
              <accordionItem :part="article"
                             :partNumber="`${article.articleNumber}`"
                             :isArticleLink="'true'"
                             v-for="article in chapter.articles"
                             :key="`${part.id}.${section.id}.${chapter.id}.${article.id}`">
              </accordionItem>
            </accordionItem>
          </accordionItem>
        </accordionItem>
      </template>
    </div>
  </div>
</template>

<script>
import accordionItem from '../components/AccordionItem.vue';

export default {
  name: 'TaxInfo',
  components: {
    accordionItem,
  },
  mounted() {
    this.getLegislationToc();
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    translations() {
      return this.$store.getters.getTranslations;
    },
    toc() {
      return this.$store.getters.getLegislationBookToc;
    },
  },
  watch: {
    lang() {
      this.getLegislationToc();
    },
  },
  methods: {
    getLegislationToc() {
      this.$store.dispatch('getLegislationBookToc', this.lang);
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/views/taxInfo.scss';
</style>
