<template>
  <div class="container">
    <div class="feedback-container">
<!--      <div class="questions-container">-->
<!--        <div class="question-box" v-for="(poll, pollIndex) in polls" :key="poll.id">-->
<!--          <div class="question">{{poll.question}}</div>-->
<!--          <div class="answer" v-if="poll.type === POLL_TYPE.YES_NO">-->
<!--            <label>-->
<!--              <input class="radio-btn" :name="poll.id" type="radio"-->
<!--                     :value="{poll: poll.id, answer: YES_NO_ANSWERS.YES}"-->
<!--                     v-model="pollData.answers[pollIndex]"/>-->
<!--              <span class="btn-label"></span>-->
<!--              <span class="btn-text">{{translations['poll_yes']}}</span>-->
<!--            </label>-->
<!--            <label>-->
<!--              <input class="radio-btn" :name="poll.id" type="radio"-->
<!--                     :value="{poll: poll.id, answer: YES_NO_ANSWERS.NO}"-->
<!--                     v-model="pollData.answers[pollIndex]"/>-->
<!--              <span class="btn-label"></span>-->
<!--              <span class="btn-text">{{translations['poll_no']}}</span>-->
<!--            </label>-->
<!--          </div>-->
<!--          <div class="answer" v-if="poll.type === POLL_TYPE.RATE">-->
<!--            <label v-for="index in 5" :key="`rate${index}`">-->
<!--              <input class="radio-btn" :name="poll.id" type="radio"-->
<!--                     :value="{poll: poll.id, answer: index}"-->
<!--                     v-model="pollData.answers[pollIndex]"/>-->
<!--              <span class="btn-label"></span>-->
<!--              <span class="btn-text">{{index}}</span>-->
<!--            </label>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="suggestion-container">
        <div class="col">
          <label>
            <input class="input-box" type="email" :placeholder="translations['email']"
                   v-model="pollData.email"/>
          </label>
          <label>
            <textarea class="input-box" rows="10" :placeholder="translations['suggestion']"
                      v-model="pollData.text">
            </textarea>
          </label>
          <button type="button"
                  class="send" :class="{'disabled': !pollIsValid}"
                  @click="submitPoll">{{translations['send']}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { POLL_TYPE, YES_NO_ANSWERS } from '../constants';

export default {
  name: 'Feedback',
  data() {
    return {
      pollData: {
        answers: [],
        email: '',
        text: '',
      },
      POLL_TYPE,
      YES_NO_ANSWERS,
      emailRegex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    translations() {
      return this.$store.getters.getTranslations;
    },
    polls() {
      return this.$store.getters.getPolls;
    },
    pollIsValid() {
      // return this.pollData.answers.length === this.polls.length &&
      return this.emailRegex.test(this.pollData.email) && this.pollData.email;
    },
  },
  created() {
    // this.getQuestions();
  },
  methods: {
    getQuestions() {
      this.$store.dispatch('getPolls', this.lang);
    },
    submitPoll() {
      this.$store.dispatch('submitPoll', { lang: this.lang, data: this.pollData });
    },
  },
};
</script>

<style scoped lang="scss">
  @import "@/assets/styles/views/feedback.scss";
</style>
