<template>
  <div>
    <div class="toc-sections">
      <div class="part">
        <b>{{partNumber}} </b>
        <span v-if="!isArticleLink">
          {{part.title}}
<!--          <template v-if="!itemIsOpen">-->
<!--            <svg class="plus" xmlns="http://www.w3.org/2000/svg"-->
<!--                 width="11" height="11" viewBox="0 0 11 11">-->
<!--              <g transform="translate(-1.5 -5.5)">-->
<!--                <path class="a" d="M10,5V15m5-5H5" transform="translate(-3 1)"/>-->
<!--              </g>-->
<!--            </svg>-->
<!--          </template>-->
<!--          <template v-if="itemIsOpen">-->
<!--            <svg class="minus" xmlns="http://www.w3.org/2000/svg"-->
<!--                 width="11" height="1" viewBox="0 0 11 1">-->
<!--              <g transform="translate(-1.5 -10.5)">-->
<!--                <path d="M15,10H5" transform="translate(-3 1)" fill="none" stroke="#333"-->
<!--                      stroke-linecap="round" stroke-width="1"-->
<!--                      fill-rule="evenodd"/>-->
<!--              </g>-->
<!--            </svg>-->
<!--          </template>-->
        </span>
        <router-link tag="a" :to="{name: 'TaxInfoArticle', params: {articleId: part.id},}"
                     v-if="isArticleLink">
          {{part.title}}
        </router-link>
      </div>
      <template>
        <div class="nested-part">
          <slot></slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccordionItem',
  props: ['part', 'partNumber', 'isArticleLink'],
  data() {
    return {
      itemIsOpen: true,
    };
  },
  methods: {
    toggleOpen() {
      this.itemIsOpen = !this.itemIsOpen;
    },
  },
};
</script>

<style scoped lang="scss">
  .toc-sections {
    list-style: none;
    padding: 0;
    font-size: 16px;

    .nested-part {
      padding-left: 30px;

      a {
        color: $darkGray;
        font-size: inherit;

        &:hover {
          text-decoration: underline;
        }
      }

      @media screen and (max-width: 768px) {
        padding-left: 20px;
      }
    }
    @media screen and (max-width: 480px) {
      font-size: 14px;
    }

    svg {
      margin-left: 5px;
      cursor: pointer;
      .a {
        fill: none;
        stroke: #333;
        stroke-linecap: round;
        fill-rule: evenodd;
      }
    }
    .minus {
      margin-bottom: 5px;
    }
  }
</style>
