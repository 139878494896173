const POLL_TYPE = {
  RATE: 1,
  YES_NO: 2,
};
const YES_NO_ANSWERS = {
  NO: 6,
  YES: 7,
};
export {
  POLL_TYPE,
  YES_NO_ANSWERS,
};
