import { render, staticRenderFns } from "./TaxInfoArticle.vue?vue&type=template&id=7402ab3a&scoped=true&"
import script from "./TaxInfoArticle.vue?vue&type=script&lang=js&"
export * from "./TaxInfoArticle.vue?vue&type=script&lang=js&"
import style0 from "./TaxInfoArticle.vue?vue&type=style&index=0&id=7402ab3a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7402ab3a",
  null
  
)

export default component.exports