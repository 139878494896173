import Cookies from 'js-cookie';

export default {
  getLang(state) {
    return state.lang;
  },
  getGuideList(state) {
    return Object.keys(state.guideList)
      .filter(key => state.guideList[key].guides.length > 0)
      .reduce((obj, key) => {
        obj[key] = state.guideList[key];
        return obj;
      }, {});
  },
  getGuideSectionsList(state) {
    return state.guideSectionsList.items;
  },
  getActiveSectionId(state) {
    return state.activeSectionId;
  },
  getLegislationBookToc(state) {
    return state.legislationBookToc;
  },
  getArticleData(state) {
    return state.articleData;
  },
  getTranslations(state, getters) {
    return state.translations[getters.getLang];
  },
  getSearchFor(state) {
    return state.searchFor;
  },
  getSearchQuery(state) {
    return state.searchQuery;
  },
  getSearchResults(state) {
    return state.searchResults;
  },
  getActiveRequestsCount(state) {
    return state.activeRequestsCount;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
  getPolls(state) {
    return state.polls;
  },
  getActiveGuide(state) {
    return state.activeGuide;
  },
  getDisclaimerIsShown(state) {
    return state.disclaimerIsShown || Cookies.get('disclaimerIsShown');
  },
};
