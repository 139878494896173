import Cookies from 'js-cookie';

export default {
  setLang(state, lang) {
    Cookies.set('lang', lang);
    state.lang = lang;
  },
  setGuideList(state, list) {
    state.guideList = list;
  },
  setGuideSectionsList(state, list) {
    state.guideSectionsList = list;
  },
  setActiveSectionId(state, id) {
    state.activeSectionId = id;
  },
  setArticleData(state, data) {
    state.articleData = data;
  },
  setLegislationBookToc(state, toc) {
    state.legislationBookToc = toc;
  },
  setSearchFor(state, searchFor) {
    state.searchFor = searchFor;
  },
  setSearchQuery(state, query) {
    state.searchQuery = query;
  },
  setSearchResults(state, results) {
    state.searchResults = results;
  },
  incrementActiveRequestsCount(state) {
    state.activeRequestsCount++;
  },
  decrementActiveRequestsCount(state) {
    state.activeRequestsCount--;
  },
  setCurrentPage(state, page) {
    state.currentPage = page;
  },
  setPolls(state, polls) {
    state.polls = polls;
  },
  setActiveGuide(state, guide) {
    state.activeGuide = guide;
  },
  setDisclaimerIsShown(state, bool) {
    state.disclaimerIsShown = bool;
  },
};
