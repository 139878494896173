<template>
  <div class="container" id="taxInfoContainer">
    <div class="tax-info-article">
      <h4 class="text-center">{{translations['tax_code_title']}}</h4>
      <h4 class="text-center">{{articleData.articleNumber}}</h4>
      <h3 class="text-center title">{{articleData.title}}</h3>

      <div id="article">
        <div class="content">
          <div v-for="article in articleData.points"
               :key="article.id" class="f_point">
            <span class="point-number">{{article.pointNumber}}</span>
            <span v-html="article.point"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaxInfoArticle',
  created() {
    this.getArticleData().then(() => {
      const ids = this.$route.hash ? this.$route.hash.slice(1).split('#') : [];
      if (ids.length) {
        this.scrollToPoint(ids);
      } else {
        window.scrollTo(0, 0);
      }
    });
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    translations() {
      return this.$store.getters.getTranslations;
    },
    articleId() {
      return this.$route.params.articleId;
    },
    articleData() {
      return this.$store.getters.getArticleData;
    },
  },
  watch: {
    lang() {
      this.getArticleData();
    },
  },
  methods: {
    getArticleData() {
      return this.$store.dispatch('getArticleData', { id: this.articleId, lang: this.lang });
    },
    scrollToPoint(ids) {
      const pageContainerTop = document.getElementById('taxInfoContainer').offsetTop;
      const articleContainerTop = document.getElementById('article').offsetTop;
      const articleHeadingHeight = articleContainerTop - pageContainerTop;
      if (ids.length === 1) {
        const points = document.getElementById('article').querySelectorAll('.f_point');
        if (points.length) {
          setTimeout(() => {
            window.scrollTo(0, points[ids[0] - 1].offsetTop - articleHeadingHeight);
          }, 100);
        }
      } else {
        const point = document.getElementById(`point_${ids[1]}`);
        setTimeout(() => {
          window.scrollTo(0, point.offsetTop - pageContainerTop);
        }, 100);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .tax-info-article {
    padding: 20px 0;
    h3, h4 {
      margin: 10px 0;
    }
    .content {
      margin-top: 30px;
      font-size: 16px;
      text-align: justify;
    }

    @media screen and (max-width: 480px) {
      padding: 0;

      h3 {
        font-size: 16px;
      }
      h4 {
        font-size: 14px;
      }
      .content {
        margin-top: 20px;
        font-size: 14px;
      }
    }
  }
</style>
